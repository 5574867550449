@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700&display=swap');

body{
  margin:0;
  padding:0;
  background:url('./assets/background.jpg') center center / cover;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  letter-spacing: .5px;
  font-family: 'Poppins';
}

.errorCtn a{
  color: white;
}

ul,li{
  list-style: none;
}

ul,li,p,button,a,h1,h2,h3,h4{
  margin:0;
  padding:0;
}

.logo{
  width:60px;
  height:auto;
  display: block;
  position: absolute;
  top: 40px;
  left:60px;
  z-index:1;
}

.logoCenter{
  width:60px;
  height:auto;
  display: block;
  position: absolute;
  top: 35px;
  left:calc(50% - 30px);
  z-index:1;
}

.ctnOnBoarding{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.centerMiddle{
  margin: auto;
  text-align: center;
  color: white;
  width: 50%;
}

.imgOnBoarding{
  width: 220px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.centerMiddle h1{
  color: white;
  font-size: 20px;
  margin-top: 24px;
}

.centerMiddle p{
  margin-top: 12px;
}

.buttonCenterMiddle{
  border-radius: 24px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 24px;
  cursor: pointer;
  outline: none;
  padding:12px 24px;
  font-weight: bold;
  color: white;
  background: #224FBF;
  cursor: pointer;
  outline: none;
  border:0;
  animation: leaves 1s ease-in-out infinite alternate;
  -webkit-animation: leaves 1s ease-in-out infinite alternate;
}

@keyframes leaves {
  0% {
      transform: scale(1.0);
  }
  100% {
      transform: scale(1.1);
  }
}

.markerTS{
  width:28px;
  height:auto;
  padding:0;
  display: inline-flex;
  align-items: center;
  justify-content: 'center';
  transform: translate(-50%, -50%);
}

.imgPlus{
  width:18px;
  height:auto;
}

.fullBlack{
  width: 100%;
  height:100vh;
  background:black;
  position: absolute;
  top:0;
  left:0;
  z-index: 1;
  opacity: .8;
}

.menuCtn{
  width:'auto';
  height:auto;
  position: absolute;
  bottom:40px;
  left:60px;
  z-index: 1;
}

.menuCtn ul{
  list-style: none;
}

.menuCtn li{
  width: 70px;
  height: 70px;
  display: flex;
  align-content: center;
  justify-content: center;
  background:#12308A;
  border-radius: 50%;
  transition: 450ms;
  cursor: pointer;
}

.menuCtn li:hover{
  background:#1B1B1B;
}

.menuCtn li:first-child{
  background:#1B1B1B;
}

.menuCtn li img{
  margin:auto;
  width:26px;
  height: 26px;
  display: block;
}

.menuCtn li:nth-child(2){
  margin-top:24px;
}

.menuCtn li:last-child{
  margin-top:24px;
}

.ctnModal{
  position: absolute;
  top: 60px;
  right: 40px;
  width: 400px;
  height:55vh;
  padding: 0px;
  overflow-x: scroll;
}

.modalAlertCtn{
  width: 100%;
  height:auto;
  padding:24px 0px;
  background: #D64435;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
}

.imgAlert{
  width: 30px;
  height: 'auto';
  margin:0 24px;
}

.modalAlertCtn div{
  margin-left: 0;
}

.modalAlertCtn p{
  color: white;
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
}

.modeAlerte{
  display: block;
  position: absolute;
  top: 60px;
  left: 50%;
  transform:translate(-50%, -50%);
  color: white;
  padding:9px 18px;
  background: #12308A;
  border-radius: 6px;
  font-size: 15px;
  font-weight: bold;
}

.modalAlertCtn button{
  display: block;
  color: white;
  font-size: 14px;
  background: transparent;
  border:0;
  margin-top:12px;
  text-decoration: underline;
  cursor: pointer;
  /* font-weight: bold; */
  outline: none;
}

.connectedCtn{
  position: absolute;
  bottom: 40px;
  right: 280px;
  padding:9px 18px;
  background: #12308A;
  border-radius: 6px;
}

.connectedCtn p{
  color: white;
  font-size: 15px;
  font-weight: bold;
}

.connectedGreen{
  width: 13px;
  height: 13px;
  background:green;
  display: inline-block;
  border-radius: 50%;
  margin-right: 12px;
  position:relative;
  top:1px;
}

.ctnCompteur{
  width: auto;
  height:auto;
  padding:0px 0px;
  background: #12308A;
  position: absolute;
  bottom:40px;
  right:80px;
  border-radius: 6px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.ctnCompteur div{
  background:rgba(255,255,255, .06);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.imgPlusDetection {
  width: 13px;
  height: 13px;
  display: block;
  margin:auto;
}

.ctnCompteur p{
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin-top:8px;
  padding:0 16px;
}

.ctnTimer{
  width: auto;
  height:auto;
  padding:13px 26px;
  background: #12308A;
  position: absolute;
  bottom:60px;
  right:270px;
  border-radius: 6px;
  z-index: 1;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.copyright{
  position: absolute;
  text-align: center;
  bottom: 40px;
  width: 100%;
  color: white;
  line-height: 18px;
  font-size: 12px;
  width: 250px;
  left: calc(50% - 125px);
  /* text-transform: uppercase; */
}

.modalAlertCtn span{
  font-size:12px;
  display: block;
  margin-top: 12px;
}

.ctnStats{
  width: 400px;
  height: 100vh;
  position: absolute;
  right: -420px;
  top: 0;
  background: #12308A;
  z-index: 9999;
  -webkit-box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.75);
  transition: 650ms
}

.statsH3{
  margin-top: 24px;
}

.ctnStatsShow {
  transition: 650ms;
  width: 520px;
  height: 100vh;
  position: absolute;
  right: 0px;
  top: 0;
  background: white;
  z-index: 9999;
  -webkit-box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.75);
}

.closeShowStats {
  position: absolute;
  top: 30px;
  right: 30px;
  display:block;
  width: 20px;
  height: auto;
  cursor:pointer;
}

.titleStats{
  font-size: 18px;
  color: black;
  margin-left: 30px;
  margin-top: 28px;
}

.centerStats{
  width: calc(100% - 60px);
  height: 85vh;
  margin:0 auto;
  margin-top: 40px;
  color: black;
  overflow-y:scroll;
}

.centerStats h3{
  font-size: 15px;
  line-height: 24px;
}

.statsLight{
  font-weight: 400;
  font-size: 14px;
}

.marginTopTitle{
  margin: 24px 0px;
  margin-bottom: 6px;
}

.br{
  margin-top: 32px;
}

/* Auth */
.centerOnMiddle{
  margin:auto;
  color: white;
  font-size:18px;
  font-weight: bold;
  text-align: center;
}

.centerOnMiddle input{
  width: 300px;
  height:auto;
  border:0;
  padding:12px 0px;
  border-radius: 24px;
  font-size: 16px;
  color: #12308A;
  padding-left:12px;
  margin-top: 12px;
  outline: none;
  font-weight: bold;
}

.centerOnMiddle button{
  display: block;
  margin:0 auto;
  margin-top: 24px;
  padding:12px 24px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background: #224FBF;
  cursor: pointer;
  outline: none;
  border:0;
}



/* Loader */

.loader,
.loader:after {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}
.loader {
  margin: 60px auto;
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.errorCtn{
  width: auto;
  height: auto;
  padding:24px 32px;
  background:rgba(222,44,44, 1);
  border-radius: 6px;
  margin-bottom: 36px;
  /* display:flex;
  justify-content: flex-start;
  align-items: center; */
}

.errorCtn img{
  width: 38px;
  height:auto;
  margin-right:24px;
}

.p-error{
  font-size: 14px;
  font-weight: regular !important;
  line-height: 21px;
  text-align: center;
  margin-top: 8px;
}

.ctnSetting{
  width: 100%;
  height: 100vh;
  background:rgba(0, 0, 0, .8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;
}

.ctnSettingShow{
  width: 100%;
  height: 100vh;
  background:rgba(0, 0, 0, .8);
  position: absolute;
  top: 0;
  left: 0;
  z-Index: 9999;
  display: flex;
}

.centerMiddleSetting{
  margin: auto;
  width: 380px;
  height: auto;
  padding: 24px 24px;
  background: #12308A;
  border-radius: 6px;
  position: relative;
}

.titleModalSetting{
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.centerMiddleSetting .close{
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  top: 34px;
  right: 24px;
  cursor: pointer;
}

.centerMiddleSetting ul{
  margin-top: 32px;
}

.centerMiddleSetting ul li{
  color: white;
  font-size: 13px;
  margin-bottom: 14px;
  font-weight: 500;
}

.flexLi{
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.flexLi input{
  border:0;
  background:transparent;
  border-bottom:1px solid rgba(255, 255, 255, .2);
  color: white;
  font-size:14px;
  outline: none;
  padding-bottom: 4px;
  font-weight: bold;
  width: 80px;
}

.imgSendNumber{
  width: 10px;
  height: auto;
  border:0;
} 

.buttonChangeDetection{
  border-radius: 0 !important;
  padding:0 !important;
  padding:6px 12px !important;
}

.flexLi input::placeholder{
  color: white;
}

.centerMiddleSetting ul li span{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.divFlag{
  padding:11px 12px;
  background-color: #17398d;
  border-radius: 50%;
  display: flex;
  border:2px solid transparent;
}

.divFlagBorder{
  padding:11px 12px;
  background-color: #17398d;
  border-radius: 50%;
  display: flex;
  border:2px solid white;
}

.divFlag:last-child{
  margin-left: 16px;
}

.divFlagBorder:last-child{
  margin-left: 16px;
}

.centerMiddleSetting ul li span .img-flag{
  width: 24px;
  height: auto;
  cursor: pointer;
  margin: auto;
}

.divImgMode{
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  border:2px solid transparent;
}

.divImgModeBorder{
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  border:2px solid white;
}

.centerMiddleSetting ul li span .divFlag:last-child{
  margin-left: 16px;
}

.centerMiddleSetting ul li span .divImgModeBorder:last-child{
  margin-left: 16px;
}

.divImgMode:last-child{
  margin-left: 16px;
}

.centerMiddleSetting button{
  border-radius: 24px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  padding:12px 24px;
  font-weight: bold;
  color: white;
  background: #224FBF;
  cursor: pointer;
  outline: none;
  border:0;
  /* margin: 0 auto; */
}

.centerMiddleSetting .flexButtonModal{
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.centerMiddleSetting .a-button-flexModal{
  background:transparent;
  text-decoration: none;
  color: white;
  font-size:13px;
  margin-right:14px;
  font-weight: 500;
}

.imgContact{
  width: 16px;
  height: auto;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.flexBadge{
  margin-top: 32px;
}

.badgeStore{
  width: 160px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 12px;
}

.badgeStore:last-child{
  margin-bottom: 0;
}

.ctnWeather{
  background: transparent;
  border:0;
  outline: none;
  cursor: pointer;
}

.ctnWeather img{
  width: 30px;
  height: 30px;
}

.ctnWeatherParagraphe{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ctnWeather p{
  color: white;
  font-size: 12px;
  margin-top: 4px;
  font-weight: bold;
}

.flexWeather{
}

.flexImgWeather{
  flex: 1;
  height: 180px;
  position: relative;
}

.imgIndicator {
  width: 160px;
  height: 160px;
  position: absolute;
  top: 10px;
  right: 0;
}

.imgCircle {
  width: 160px;
  height: 160px;
  position: absolute;
  top: 10px;
  right: 0;
}

.imgCompass{
  width: 100px;
  height: 100px;
  position: absolute;
  top: 39px;
  right: 30px;
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}